<template>
  <div class="payroll-deduction-scheme--what-is-it">
    <h1>What is our Payroll Deduction Scheme</h1>
    <p>Local organisations based in Tameside and Glossop can set up a Payroll Deduction Scheme with Cash Box Credit Union.  Once in place, employees can pay into their Cash Box Savings Account(s) or repay a Loan, direct from their salary.</p>
    <p>We offer</p>
    <ul>
      <li>Instant Access Savings Accounts</li>
      <li>Christmas Savings Accounts</li>
      <li>PrizeSaver Account</li>
      <li>Payroll Loans<br/>(We offer preferential interest rates on loans to employees of our Payroll Partners)</li>
    </ul>
    <p>Want to enquire about becoming a partner? Email: <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a></p>
  </div>
</template>

<script>
export default {
  name: 'Payroll Deduction Scheme What Is It',
  metaInfo() {
    return {
      title: 'What is our Payroll Deduction Scheme | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "payroll, deduction, scheme" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
